<template>
	<van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
		<div class="main-wrap">
			<div class="total-wrap">
				<div class="total-1">
					<span class="value">{{ totalData.shareNum || 0 }}</span>
					<span class="label">共享池在池（台）</span>
				</div>

				<div class="total-2">
					<div class="total-list total-list-1">
						<div class="total-list-item">
							<span class="value">{{ totalData.loan || 0 }}</span>
							<span class="label">借出</span>
						</div>
						<div class="total-list-item">
							<span class="value">{{ totalData.borrow || 0 }}</span>
							<span class="label">借入</span>
						</div>
						<div class="total-list-item">
							<span class="value">{{ totalData.orderNum || 0 }}</span>
							<span class="label">预约</span>
						</div>
						<div class="total-list-item">
							<span class="value">{{ totalData.approve || 0 }}</span>
							<span class="label">待审批</span>
						</div>
					</div>
					<div class="total-list total-list-2">
						<div class="total-list-item">
							<span class="value">{{ totalData.income || 0 }}</span>
							<span class="label">今日收益（元）</span>
						</div>
						<div class="total-list-item">
							<span class="value">{{ totalData.amount || 0 }}</span>
							<span class="label">今日支出（元）</span>
						</div>
					</div>
				</div>
			</div>

			<div class="card-list">
				<listItem1 v-for="(item, index) in tableData" :key="index" :itemData="item" :config="listItemConfig">
					<!-- <div class="row-footer" slot="footer">
						<button type="button" class="button-plan button-plan-danger" @click="refuseEvent(item)">拒绝</button>
						<button type="button" class="button-plan button-plan-primary" @click="agreeEvent(item)">同意</button>
					</div> -->
				</listItem1>
			</div>

			<KcEmpty v-if="!tableData.length"></KcEmpty>
		</div>
	</van-pull-refresh>
</template>

<script>
import listItem1 from '@components/listItem/listItem1';
import { getDate } from '@utils/utils.js';
export default {
	name: 'shareSetting',
	components: { listItem1 },
	data() {
		return {
			isRefresh: false,

			listItemConfig: {
				titleKey: 'deviceName',
				layout1: [
					{
						label: '申请人',
						key: 'orderUser'
					},
					{
						label: '申请时间',
						key: 'orderTimeText'
					}
				],
				layout2: [
					{
						label: this.$VUEAPPDEVICECODE(),
						key: 'code'
					},
					{
						label: '卡片号',
						key: 'label'
					},
					{
						label: '设备分类',
						key: 'categoryName'
					},
					{
						label: '品牌',
						key: 'brandName'
					},
					{
						label: '型号',
						key: 'modelName'
					}
				]
			},
			totalCount: 0,
			tableData: [],
			totalData: {}
		};
	},
	created() {
		this.getData();
	},
  beforeDestroy() {
    this.$bus.off('allDeviceUpdate', this.getData)
  },
	mounted() {
    this.$bus.on('allDeviceUpdate', this.getData)
  },
	methods: {
		onRefresh() {
			this.isRefresh = true;
			this.getData(() => {
				setTimeout(() => {
					this.isRefresh = false;
				}, 300);
			});
		},
		getData(callbak) {
			let userInfo = this.$store.getters.userInfo;
			const data = {
				query: '',
				page: 1,
				pageSize: 100,
				orderStatus: '',
				tenantId: userInfo.tenantId,
				isTenantIdChild: 1,
				startTime: getDate() + ' 00:00:00',
				endTime: getDate() + ' 23:59:59',
				timeType: 1
			};

			Promise.all([this.getTotal(data), this.getList(data)])
				.then(res => {
					if (callbak) callbak();
				})
				.catch(() => {
					if (callbak) callbak();
				});
		},
		getTotal(data) {
			return new Promise((resolve, reject) => {
				this.$api.deviceWorkManager
					.deviceShareOverview(data)
					.then(res => {
						this.totalData = res.data;
						resolve();
					})
					.catch(() => {
						reject();
					});
			});
		},
		getList(data) {
			return new Promise((resolve, reject) => {
				this.$api.deviceOrg
					.shareOrderListByStatus(data)
					.then(res => {
						res.data.map(item => {
							item.firstShareTimeText = this.$filters.formatDateTime(item.firstShareTime);
              item.orderTimeText = this.$filters.formatDateTime(item.orderTime);
						});
						this.tableData = res.data;
						resolve();
					})
					.catch(e => {
						reject();
					});
			});
		},

		refuseEvent(item) {
			this.$dialog
				.confirm({
					message: '是否拒绝此申请？'
				})
				.then(() => {
					this.$api.deviceWorkManager
						.deviceShareApprove({
							deviceId: item.deviceId,
							approve: 0,
							orderId: item.id
						})
						.then(res => {
							this.getData()
							this.$toast.success('已拒绝');
						})
						.catch(e => {});
				})
				.catch(() => {});
		},
		agreeEvent(item) {
			this.$dialog
				.confirm({
					message: '是否同意此申请？'
				})
				.then(() => {
					this.$api.deviceWorkManager
						.deviceShareApprove({
							deviceId: item.deviceId,
							approve: 1,
							orderId: item.id
						})
						.then(res => {
							this.getData()
							this.$toast.success('已同意');
						})
						.catch(e => {});
				})
				.catch(() => {});
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
@import '@styles/layout.scss';

.total-wrap {
	margin-top: 38px;
	.total-1 {
		display: flex;
		flex-direction: column;
		align-items: center;
		.value {
			font-size: 36px;
			font-weight: 700;
			color: #fff;
			line-height: 36px;
		}
		.label {
			font-size: 12px;
			color: #fff;
			margin-top: 6px;
		}
	}

	.total-2 {
		width: calc(100% - 44px);
		margin-left: 22px;
		height: 143px;
		background: #ffffff;
		border-radius: 20px;
		margin-top: 16px;
		padding: 10px;
		.total-list {
			display: flex;
			height: 50%;
			&.total-list-1 {
				border-bottom: 1px solid #f2f2f2;
				.total-list-item {
					width: 25%;
				}
			}
			&.total-list-2 {
				.total-list-item {
					width: 50%;
					.value {
						color: #3d80f1;
					}
				}
			}
			.total-list-item {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				height: 100%;
				.value {
					font-size: 20px;
					font-weight: bold;
					color: #333333;
				}
				.label {
					margin-top: 4px;
					font-size: 12px;
					color: #999999;
				}
			}
		}
	}
}

.card-list {
	margin-top: 10px;
}

.row-footer {
	justify-content: flex-end;
}
</style>
